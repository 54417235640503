@import url('https://fonts.googleapis.com/css2?family=Schoolbell:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Bubbles&family=Schoolbell:wght@300;400;500;600;700&display=swap');

:root{
  /* FONT FAMILY */
  --fontFamily-Rubik: "Rubik Bubbles", system-ui;
  --fontFamily-QuickSand: "Schoolbell", cursive;

  /* BORDER COLOUR */
  --border-blue-jeans: hsla(202, 100%, 50%, 1);
  --border-white-alpha-10: hsla(0, 0%, 100%, 0.1);

  /* OTHER */
  --body-color-grey: #F1F0F6;
  --bar-color-light: #FFF;
  --yellow-dark-color-txt: #64F340;
  --tertiary-color-dark-blue: #7977F5;
  --non-color-dark: #8D8D8D;
  --toggle-color: #DDD;
  --text1-color-dark-grey: #fefefe;
  --border-color: 4px solid #64F340;
  --box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;;
  --box-shadow-second: 0 .5rem 1rem rgba(0, 0, 0, .1);
  --text-shadow: 0 1.5rem 3rem rgba(0, 0, 0, .3);

  --tran-04: all 0.4s ease;
  --tran-06: all 0.6s ease;
  --tran-10: all 1.0s ease;
}
*, ::before, ::after{
  margin: 0;
  padding: 0;
  box-sizing: 0;
}
*{
  font-family: var(--fontFamily-QuickSand);
  margin: 0;
  font-size: 20px;
  line-height: 35px;
  box-sizing: border-box;
  font-weight: 400;
}
a{
  text-decoration: none;
  color: var(--text1-color-dark-grey);
}
i{
  font-weight: 400;
  color: var(--bar-color-light);
  font-size: 28px;
}
img, span, button{
  display: block;
  cursor: pointer;
}
h1, h2, h3, h4, h5, h6{
  font-family: var(--fontFamily-Rubik);
  color: var(--text1-color-dark-grey);
}
h1, h2{
  font-size: 38px;
  text-align: center;
  text-transform: uppercase;
  text-shadow: var(--text-shadow);
}
h4{
  font-size: 35px;
  text-align: center;
  text-shadow: var(--text-shadow);
  font-family: var(--fontFamily-QuickSand);

}
button{
  background: #ff0000;
  color: var(--bar-color-light);
  border: 4px solid var(--bar-color-light);
  padding: 0.6rem 2rem;
  border-radius: 10px;
  text-transform: uppercase;
  transition: var(--tran-06);
  box-shadow: var(--box-shadow);
}
button:hover{
  transform: scale(0.89);
  color: var(--text1-color-dark-grey);
  border-color: #d70000;
}
input, button, textarea{
  font: inherit;
}
span{
  border-radius: 30px;
  height: fit-content;
  width: fit-content;
  overflow: hidden;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border: 5px solid var(--bar-color-light);
  transition: var(--tran-06);
  box-shadow: var(--box-shadow);
}
span:hover{
  border-color: #d70000;
}
html{
  font-family: var(--fontFamily-QuickSand);
  font-size: 18px;
  scroll-behavior: smooth;
}
html::-webkit-scrollbar{
  width: 4px;
}
html::-webkit-scrollbar-track{
  background: #f1f1f1;
}
html::-webkit-scrollbar-thumb{
  background: #888;
}
html::-webkit-scrollbar-thumb:hover{
  background: #555;
}
body{
  height: 100%;
  background-image: url(./pic/royal-watercolor-valentine-red-background_1340-4006.avif);
  background-repeat: no-repeat;
  background-position: "center";
  background-size: cover;
}
.socialLink{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 30px;
  height: 90px;
}
.socialLink span img{
  width: 50px;
  height: 50px;
  border: 2px solid transparent;
  border-radius: 80px;
  transition: var(--tran-06);
}
.socialLink span img:hover{
  border-color: var(--bar-color-light);
  transform: scale(1.1);
}
.logoText{
  position: relative;
  display: block;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  border-radius: 30px;
  padding: 1rem 0;
}
.logoText div{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.5rem 0 0.5rem 0;
}
.logoText div span img{
  transition: var(--tran-06);
  width: 320px;
  height: 300px;
}
.logoText div span img:hover{
  transform: scale(1.1);
  border-color: 5px solid black;
}
.logoText h4{
  font-family: var(--fontFamily-Rubik);
}
.caSection {
  display: flex;
  align-items: center;
  justify-content: center;
}
.caSection div{
  border: 1px solid var(--bar-color-light);
  padding: 0.1rem 1rem;
  border-radius: 10px;
  position: relative;
}
.caSection div h4{
  text-align: start;
  font-size: 20px;
}
.caSection div p{
  font-size: 18px;
  text-wrap: wrap;
  color: var(--bar-color-light);
}
.aboutSection{
  margin: 3rem 0 1rem 0;
  padding: 1.8rem 1rem;
  border-radius: 30px;
}
.aboutSection .about{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.aboutSection .about span img{
  width: 280px;
  height: 280px;
  transition: var(--tran-06);
}
.aboutSection .about span img:hover{
  transform: scale(1.1);
  border-color: 5px solid black;
}
.aboutSection .about h4{
  width: 500px;
  height: auto;
}
.aboutSection .buyButton{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
}
.buyButton {
  left: 5rem;
}
.memeSection{
  padding: 2rem 0.5rem;
}
.memeSection h4{
  font-size: 35px;
  margin-bottom: 2rem;
  font-family: var(--fontFamily-Rubik);
}
.memeSection .memePic{
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  padding: 0  2rem;
}
.memePic span img{
  width: 300px;
  height: 300px;
}
footer{
  bottom: 0;
  padding: 0.4rem 0;
}
footer p{
  text-align: center;
  text-wrap: wrap;
  font-size: 25px;
  color: var(--bar-color-light);
}
@media screen and (max-width: 900px) {
  .aboutSection .about{
    display: grid;
    gap: 25px;
    justify-items: center;
  }
  .memePic span img{
    width: 100%;
    height: 400px;
  }
}
@media screen and (max-width: 630px){
  h1, h2{
    font-size: 25px;
  }
  .socialLink{
    gap: 20px;
    height: 90px;
  }
  .socialLink span img{
    width: 45px;
    height: 45px;
  }
  .logoText{
    margin: 0.6rem 0;
  }
  .logoText div{
    margin: 1.5rem 0 0.5rem 0;
  }
  .logoText div span img{
    width: 280px;
    height: 250px;
  }
  .logoText h4{
    font-size: 22px;
  }
  .caSection div{
    width: 70%;
    overflow: hidden;
    padding: 0.1rem 0.4rem;
  }
  .caSection div h4{
    font-size: 20px;
  }
  .caSection div p{
    font-size: 15px;
    text-align: center;
  }
  .aboutSection .about h4{
    width: 90%;
    height: auto;
    font-size: 21px;
  }
  .memeSection{
    padding: 2rem 1.5rem;
  }
  .memeSection h4{
    font-size: 25px;
    font-family: var(--fontFamily-QuickSand);
  }
  .memeSection .memePic{
    width: 98%;
    gap: 5px;
    padding: 0;
  }
  .memePic span img{
    width: 120px;
    height: 120px;
  }
  footer{
    padding: 0.4rem 0.1rem;
  }
  footer p{
    font-size: 16px;
  }
}
@media screen and (max-width: 420px){
  h1, h2{
    font-size: 25px;
    padding: 0 0.2rem;
  }
  h4{
    font-size: 24px;
  }
  .socialLink{
    gap: 10px;
  }
}
